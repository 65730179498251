import React from "react"
import { navigate } from "gatsby"

import RrLogo from "../assets/img/rapyuta_robotics_logo.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Progress from "../components/progress"
import {
  redirectViaVerifyAPI,
  setLoginTokens
} from "../utils"
import CenteredContainer from "../components/CenteredContainer"
import FancyInput, { FancyCardHeader } from "../components/FancyInput"


  
class AuthToken extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const cookieSetSuccefully = setLoginTokens()
    setTimeout(() => {
      redirectViaVerifyAPI()
    }, 500)

  }


  render() {
    return (
      <Layout bgimage dark stickyFooter>
        <SEO title="Get Auth Token" />
          <CenteredContainer>
                <FancyCardHeader title="Logging In" />
                  <div id="success">
                    <Progress />  
                    <p id="redirectParagraph" className="subText">
                      <span>Redirecting</span>
                    </p>
                  </div>
          </CenteredContainer>
      </Layout>
    )
  }
}

export default AuthToken
